"use client";
import { DM_Mono, DM_Sans } from "next/font/google";
import "./globals.css";
import { AppDevTool } from "../components/app-dev-tool";
import { SiteHeader } from "../components/site-header";
import { ScrollArea } from "@trident/ui/scroll-area";
import { Toaster } from "@trident/ui/toast";
import { TRPCReactProvider } from "~/trpc/react";
import { CSPostHogProvider } from "~/providers/posthog";
import { RealtimeProvider } from "~/providers/realtime";
import { AuthProvider } from "~/providers/auth2";
import dynamic from "next/dynamic";
import { Suspense } from "react";
import useMediaQuery from "~/hooks/use-media-query";
import { FormStoreProvider } from "~/providers/form-store";

const dmSans = DM_Sans({
  variable: "--font-sans",
  subsets: ["latin-ext"],
  display: "swap",
});

const dmMono = DM_Mono({
  variable: "--font-mono",
  weight: ["300", "400", "500"],
  subsets: ["latin"],
  display: "swap",
});

const PostHogPageView = dynamic(() => import("../providers/posthog-pageview"), {
  ssr: false,
});

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { width } = useMediaQuery();
  return (
    <html
      lang="en"
      className={`${dmSans.variable} ${dmMono.variable}`}
      suppressHydrationWarning
    >
      <body className="min-h-screen bg-grayscale-1 antialiased">
        <TRPCReactProvider>
          <FormStoreProvider>
            <AuthProvider>
              <CSPostHogProvider>
                <Suspense>
                  <PostHogPageView />
                </Suspense>
                <ScrollArea className="flex h-screen flex-col">
                  <SiteHeader />
                  {!!width && width < 800 ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="flex w-72 flex-col items-center gap-5 rounded-sm border border-grayscale-6 bg-[#F8F8F8] pb-[72px]">
                        <h1 className="p-5 font-sans text-[20px] font-medium leading-6">
                          Mobile Device Detected
                        </h1>
                        <p className="px-5 text-base leading-6">
                          The Anchorwatch application is a better experience on
                          a desktop browser. To continue please open the
                          application on your desktop.
                        </p>
                      </div>
                    </div>
                  ) : (
                    children
                  )}
                  <Toaster />
                </ScrollArea>
                <RealtimeProvider />
              </CSPostHogProvider>
            </AuthProvider>
          </FormStoreProvider>
        </TRPCReactProvider>
      </body>
    </html>
  );
}
