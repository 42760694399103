"use client";

import Link from "next/link";
import { Icons } from "./icons";
import { siteConfig } from "~/config/site";

export function MainNav() {
  return (
    <div className="mr-4 flex">
      <div className="mr-4 flex items-center space-x-4 lg:mr-6">
        <Link href="/">
          <Icons.logo className="size-6" />
        </Link>
        <span className="inline-block font-mono text-[20px] font-medium uppercase leading-6">
          {siteConfig.name}
        </span>
      </div>
      {/* <nav className="flex items-center gap-4 text-sm lg:gap-6">
        links would go here
      </nav> */}
    </div>
  );
}
