type IconProps = React.HTMLAttributes<SVGElement>;

export const Icons = {
  logo: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_16591_21508)">
        <path
          d="M31.999 15.9789C31.999 15.8272 31.9961 15.6754 31.9913 15.5246C31.9913 15.5101 31.9913 15.4966 31.9894 15.4821C31.8734 11.8174 30.5248 8.463 28.344 5.82106L26.1874 7.55528C26.6746 8.14302 27.1125 8.7733 27.4933 9.44031L27.4711 9.45288C28.5886 11.3949 29.2305 13.6425 29.2305 16.0437V16.0505C29.2208 18.4227 28.5847 20.6451 27.4827 22.5678L27.5049 22.5804C25.2284 26.5786 20.9286 29.2756 15.9985 29.2756C11.0685 29.2756 6.78513 26.5882 4.50569 22.6026L4.52889 22.5891C3.41141 20.647 2.76954 18.3995 2.76954 15.9983V15.9925C2.76954 15.862 2.77147 15.7315 2.7763 15.601C2.7763 15.599 2.7763 15.5961 2.7763 15.5942C2.85074 13.3718 3.47715 11.2896 4.51729 9.47511L4.49603 9.46254C4.89817 8.7559 5.36314 8.08986 5.88515 7.47215L3.75458 5.70312C1.41135 8.4862 0 12.0784 0 15.9992C0 16.0244 0.00096668 16.0495 0.00096668 16.0746C0.00096668 16.2264 0.00386672 16.3772 0.00870012 16.528C0.00870012 16.5396 0.00870012 16.5512 0.0096668 16.5628C0.283237 25.1585 7.33807 32.0442 15.9995 32.0442C24.661 32.0442 31.999 24.8801 31.999 16.0447C31.999 16.0224 31.9981 16.0002 31.9981 15.9789H31.999Z"
          fill="#002C2F"
        />
        <path
          d="M15.9991 26.7355C19.9142 26.7355 23.3314 24.6059 25.1594 21.4439L22.7891 20.075C21.661 22.0258 19.716 23.4401 17.425 23.8625V2.88457H17.4182V2.87201C17.4463 2.87587 17.4733 2.87877 17.5014 2.88264C17.6802 2.90197 17.8561 2.92711 18.033 2.95321C18.0756 2.95997 18.1162 2.96577 18.1587 2.97254C18.3482 3.00444 18.5347 3.04021 18.7213 3.07887C18.7851 3.09337 18.8508 3.10691 18.9146 3.12141C19.0867 3.16008 19.2588 3.20164 19.4289 3.24708C19.4995 3.26641 19.5691 3.28574 19.6397 3.30701C19.7624 3.34278 19.8823 3.37758 20.0031 3.41721C20.0834 3.44235 20.1646 3.46845 20.2438 3.49551C20.3308 3.52548 20.4159 3.55738 20.5019 3.58831L20.5126 3.55738C20.5213 3.56028 20.529 3.56318 20.5377 3.56608L21.4783 0.962813C19.7692 0.340271 17.9228 0 15.9982 0C14.0735 0 12.2658 0.333505 10.5712 0.944446L10.5809 0.971513C10.5722 0.974413 10.5635 0.977313 10.5548 0.98118L11.4973 3.59122C11.5785 3.56221 11.6597 3.53128 11.7419 3.50325C11.8231 3.47521 11.9052 3.45008 11.9864 3.42301C12.1102 3.38435 12.2349 3.34665 12.3606 3.30991C12.4389 3.28671 12.5162 3.26544 12.5964 3.24418C12.7559 3.20164 12.9154 3.16298 13.0769 3.12721C13.1407 3.11271 13.2064 3.09917 13.2702 3.08467C13.4568 3.04601 13.6443 3.01024 13.8328 2.97834C13.8773 2.97157 13.9217 2.96384 13.9672 2.95901C14.1431 2.93291 14.3181 2.90777 14.4959 2.88844C14.524 2.88457 14.551 2.88167 14.5791 2.87974V2.89231H14.5742V23.8635C12.2813 23.442 10.3383 22.0248 9.21014 20.076L6.83984 21.4448C8.66977 24.6068 12.085 26.7364 16.0001 26.7364L15.9991 26.7355Z"
          fill="#002C2F"
        />
        <path
          d="M14.5723 2.89153H14.5742V2.88477L14.5723 2.89153Z"
          fill="#002C2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_16591_21508">
          <rect width="32" height="32.0445" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  house: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_16591_18766)">
        <path
          d="M19.2863 9.91484C19.2876 9.71656 19.2477 9.52016 19.169 9.33816C19.0903 9.15616 18.9746 8.99253 18.8292 8.8577L10.0006 0.714844L1.17202 8.8577C1.02663 8.99253 0.910895 9.15616 0.832191 9.33816C0.753487 9.52016 0.713535 9.71656 0.714876 9.91484V17.8577C0.714876 18.2366 0.865386 18.5999 1.1333 18.8679C1.4012 19.1358 1.76457 19.2863 2.14345 19.2863H17.8577C18.2366 19.2863 18.6 19.1358 18.8679 18.8679C19.1358 18.5999 19.2863 18.2366 19.2863 17.8577V9.91484Z"
          stroke="#147C83"
          strokeWidth="1.43017"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16591_18766">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  question_bubble: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.8557 0.712891H2.14146C1.76258 0.712891 1.39922 0.8634 1.13131 1.13131C0.8634 1.39922 0.712891 1.76258 0.712891 2.14146V14.2843C0.712891 14.6632 0.8634 15.0266 1.13131 15.2945C1.39922 15.5624 1.76258 15.7129 2.14146 15.7129H4.99861V19.2843L9.45575 15.7129H17.8557C18.2346 15.7129 18.598 15.5624 18.8659 15.2945C19.1338 15.0266 19.2843 14.6632 19.2843 14.2843V2.14146C19.2843 1.76258 19.1338 1.39922 18.8659 1.13131C18.598 0.8634 18.2346 0.712891 17.8557 0.712891Z"
        stroke="#147C83"
        strokeWidth="1.43017"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.85547 6.42801C7.85547 6.0042 7.98115 5.5899 8.21661 5.23751C8.45207 4.88512 8.78673 4.61046 9.17829 4.44827C9.56985 4.28608 10.0007 4.24365 10.4164 4.32633C10.832 4.40901 11.2139 4.6131 11.5136 4.91279C11.8132 5.21247 12.0173 5.59429 12.1 6.00996C12.1827 6.42564 12.1403 6.85649 11.9781 7.24805C11.8159 7.63961 11.5412 7.97427 11.1888 8.20973C10.8364 8.44519 10.4221 8.57087 9.99833 8.57087V9.28516"
        stroke="#147C83"
        strokeWidth="1.43017"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99721 11.4277C9.7853 11.4277 9.57815 11.4906 9.40196 11.6083C9.22576 11.726 9.08843 11.8934 9.00734 12.0891C8.92625 12.2849 8.90503 12.5004 8.94637 12.7082C8.98771 12.916 9.08975 13.1069 9.2396 13.2568C9.38944 13.4066 9.58035 13.5087 9.78818 13.55C9.99602 13.5913 10.2115 13.5701 10.4072 13.489C10.603 13.4079 10.7703 13.2706 10.8881 13.0944C11.0058 12.9182 11.0686 12.7111 11.0686 12.4992C11.0649 12.2162 10.9509 11.9458 10.7507 11.7456C10.5506 11.5455 10.2802 11.4314 9.99721 11.4277Z"
        fill="#147C83"
      />
    </svg>
  ),
  logout: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.28711 9.99805H19.2871"
        stroke="#147C83"
        strokeWidth="1.43017"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4297 7.14062L19.2868 9.99777L16.4297 12.8549"
        stroke="#147C83"
        strokeWidth="1.43017"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7168 16.4135C15.434 17.7506 13.7805 18.6733 11.969 19.063C10.1575 19.4527 8.27088 19.2915 6.55174 18.6002C4.83261 17.9088 3.35961 16.719 2.32221 15.1837C1.28482 13.6484 0.730469 11.8379 0.730469 9.98494C0.730469 8.13201 1.28482 6.32145 2.32221 4.78615C3.35961 3.25085 4.83261 2.06102 6.55174 1.36971C8.27088 0.678394 10.1575 0.51721 11.969 0.906889C13.7805 1.29657 15.434 2.21929 16.7168 3.55637"
        stroke="#147C83"
        strokeWidth="1.43017"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
