"use client";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@trident/ui/dialog";
import { Icons } from "./icons";
import { Button } from "@trident/ui/button";
import { useAuth } from "~/providers/auth2";
import { useState } from "react";
import { LogInIcon } from "lucide-react";
import Link from "next/link";

export function SigninSignout() {
  const [open, setOpen] = useState(false);
  const { logout, session } = useAuth();
  async function handleLogout() {
    await logout();
    setOpen(false);
  }

  return (
    <>
      {session ? (
        <Dialog open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
          <DialogTrigger>
            <Icons.logout className="size-5 cursor-pointer" />
          </DialogTrigger>
          <DialogContent className="max-w-[525px]">
            <DialogHeader>
              <DialogTitle>Are you absolutely sure?</DialogTitle>
              <div className="flex flex-col gap-6 p-6">
                <DialogDescription className="pb-20 text-lg text-dark-teal-2">
                  Please make sure you have saved any work before you logout.
                </DialogDescription>

                <div>
                  <Button onClick={handleLogout}>Confirm</Button>
                </div>
              </div>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      ) : (
        <Link href={"/auth/signin"}>
          <LogInIcon className="text-light-teal-1" />
        </Link>
      )}
    </>
  );
}
