import * as React from "react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "@trident/ui";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md font-sans text-xl font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-1 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        quickAction:
          "w-full justify-start gap-5 border border-border bg-background font-mono text-[20px] uppercase leading-6 text-dark-teal-5 hover:bg-accent/90",
        secondary:
          "text-button-secondary-fg border-button-secondary-fg border text-sm leading-5 hover:bg-light-teal-4 dark:border-light-teal-4 dark:hover:bg-dark-teal-5",
        default:
          "bg-button-secondary-fg text-primary-foreground hover:bg-primary/80 disabled:bg-disabled-primary-bg disabled:text-disabled-primary-fg",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-light-teal-1 bg-transparent text-light-teal-1 hover:border-light-teal-1 hover:bg-light-teal-4 hover:text-light-teal-1 focus-visible:border-light-teal-1 focus-visible:bg-light-teal-3 focus-visible:text-dark-teal-5 active:border-light-teal-1 active:text-dark-teal-5 dark:border-light-teal-4 dark:text-light-teal-5 dark:hover:border-light-teal-5 dark:hover:bg-dark-teal-5 dark:focus-visible:border-yellow-2 dark:focus-visible:bg-dark-teal-5 dark:focus-visible:text-yellow-2 dark:active:border-yellow-2 dark:active:text-yellow-2",
        subtle:
          "bg-slate-100 text-slate-900 hover:bg-slate-200 dark:bg-slate-700 dark:text-slate-100",
        ghost:
          "bg-transparent text-dark-teal-2 hover:text-dark-teal-2 focus-visible:text-dark-teal-2 data-[state=open]:bg-transparent dark:text-light-teal-3 dark:hover:text-yellow-1 dark:focus-visible:text-yellow-1 dark:data-[state=open]:bg-transparent",
        link: "bg-transparent text-light-teal-4 underline-offset-4 hover:bg-transparent hover:text-yellow-3 hover:underline focus-visible:text-yellow-1 active:text-yellow-1 dark:bg-transparent dark:hover:bg-transparent",
        yellow:
          "flex justify-start rounded-[2.519px] bg-yellow-1 px-[30px] py-[12.595px] text-black",
        transactionButton: "bg-none font-mono text-base font-normal uppercase",
        comboboxBtn:
          "flex w-full rounded-md border border-input bg-grayscale-2 text-lg text-dark-teal-2 ring-offset-grayscale-2 file:border-0 file:bg-transparent file:text-sm file:font-medium hover:border-light-teal-1 focus:outline-none focus:ring-1 focus:ring-dark-teal-2 focus:invalid:ring-sys-red-main disabled:cursor-not-allowed disabled:opacity-50 dark:border-dark-teal-3 dark:bg-dark-teal-3 dark:text-light-teal-2 focus:dark:ring-light-teal-2",
      },
      size: {
        xxs: "h-4 px-2 py-2 text-base",
        md: "h-8 px-5",
        default: "h-10 px-8 py-2",
        sm: "h-8 px-2 text-sm",
        lg: "h-11 px-8",
        icon: "h-10 w-10",
        xs: "h-7 px-5",
        xl: "h-12 px-5 py-3",
        xxl: "h-[48px] w-[182px]",
        quickAction: "h-[38px] px-5 py-3 2xl:h-12",
        walletCard: "h-8 px-5",
        transaction: "h-12 px-[10px] py-[15px]",
        alert: "h-[30px] px-[20px] py-[10px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, isLoading = false, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          isLoading && "pointer-events-none opacity-30",
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
