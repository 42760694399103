"use client";
import { create } from "zustand";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createInitializedContext } from "./context";
import { getSession } from "~/app/actions";
import { useResetFormValues } from "~/stores/main-form-store";
import { useRouter } from "next/navigation";

interface AuthStore {
  token: string | null;
  actions: {
    setToken: (token: string) => void;
  };
}

export const authStoreV2 = create<AuthStore>((set) => ({
  token: null,
  actions: {
    setToken: (token: string) => {
      set(() => ({ token }));
    },
  },
}));

export const getAuthToken = () => authStoreV2.getState().token;

export const { use: useAuth, provider: AuthProvider } =
  createInitializedContext("AuthContext", () => {
    const info = useGetUserInfo();
    const reset = useResetFormValues();
    const queryClient = useQueryClient();
    const router = useRouter();

    const result = {
      get ready() {
        return info.isSuccess;
      },
      get session() {
        return info.data?.properties;
      },
      get token() {
        return info.data?.token;
      },
      get type() {
        return info.data?.type;
      },
      get logout() {
        return async () => {
          try {
            const response = await fetch("/api/auth/logout", {
              method: "GET",
              credentials: "include",
            });
            if (response.ok) {
              authStoreV2.setState({ token: null });
              queryClient.setQueryData(["user-info"], null);
              reset();
              router.push("/");
            } else {
              console.error("Logout failed:", response.status);
            }
          } catch (error) {
            console.error("Logout error:", error);
          }
        };
      },
    };
    return result;
  });

function useGetUserInfo() {
  return useQuery({
    queryKey: ["user-info"],
    queryFn: getUserInfo,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
  });
}

const getUserInfo = async () => {
  try {
    const data = await getSession();
    if (!data) return null;
    if (!data.token) return null;
    authStoreV2.getState().actions.setToken(data.token);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
