"use client";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useAuth } from "./auth2";

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  const { session } = useAuth();
  if (
    typeof window !== "undefined" &&
    process.env.NEXT_PUBLIC_IS_DEV === "false"
  ) {
    // don't worry, this is not private
    if (!posthog.__loaded)
      posthog.init("phc_1EWubTC477EvQA8763J177KZ4eZvi9Ly5ykFfXq6tw4", {
        api_host: "https://" + process.env.NEXT_PUBLIC_POSTHOG_HOST,
        ui_host: process.env.NEXT_PUBLIC_SITE_URL,
        person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
        loaded: (posthog) => {
          // if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
          if (session) {
            posthog.identify(session.userID, { email: session.email });
            posthog.setPersonProperties({ email: session.email });
          }
        },
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
            // color: false,
            // date: false,
            // 'datetime-local': false,
            // email: false,
            // month: false,
            // number: false,
            // range: false,
            // search: false,
            // tel: false,
            // text: false,
            // time: false,
            // url: false,
            // week: false,
            // textarea: false,
            // select: false,
          },
        },
        //   capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      });
  }
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
