"use client";

import { useAuth } from "./auth2";
import { useEffect } from "react";
import { api } from "~/trpc/react";
import mqtt from "mqtt";
import { toast } from "@trident/ui/toast";

function createConnection(token: string) {
  const endpoint = process.env.NEXT_PUBLIC_IOT_HOST;
  const authorizer = process.env.NEXT_PUBLIC_IOT_AUTHORIZER;

  return mqtt.connect(
    `wss://${endpoint}/mqtt?x-amz-customauthorizer-name=${authorizer}`,
    {
      protocolVersion: 5,
      manualConnect: true,
      username: "", // Must be empty for the authorizer
      password: token, // Passed as the token to the authorizer
      clientId: `client_${window.crypto.randomUUID()}`,
    },
  );
}

export function RealtimeProvider() {
  const utils = api.useUtils();
  const { session, token } = useAuth();

  useEffect(() => {
    if (!session || !token) return;
    const topic = `${process.env.NEXT_PUBLIC_NAME}/${process.env.NEXT_PUBLIC_STAGE}/${session.userID}/all/#`;

    const connection = createConnection(token);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    void connection.on("connect", async () => {
      try {
        await connection.subscribeAsync(topic, { qos: 1 });
        console.log("WS connected");
      } catch (e) {
        console.error(e);
      }
    });

    connection.on("error", (e) => {
      console.log("connection error", e, e.name, e.cause, e.message, e.stack);
    });

    connection.on("message", (fullTopic, payload) => {
      handleMessage(fullTopic, payload, utils);
    });

    connection.on("disconnect", console.log);

    void connection.connect();

    return () => {
      void connection.end();
    };
  }, [session, session?.userID, token, utils]);

  return null;
}

function handleMessage(
  fullTopic: string,
  payload: ArrayBuffer,
  utils: ReturnType<typeof api.useUtils>,
) {
  const splits = fullTopic.split("/");
  const topic = splits[4];
  if (!topic) return;
  const message = new TextDecoder("utf8").decode(new Uint8Array(payload));
  const parsed = JSON.parse(message) as {
    properties: Record<string, unknown>;
  };

  console.log(fullTopic);

  switch (topic) {
    case "poke":
      console.log("got poke", parsed);
      break;

    case "mitek-verification":
      console.log("got mitek-verification", parsed);
      void utils.mitek.status.invalidate();
      break;

    case "hasPaid":
      console.log("got hasPaid", parsed);
      void utils.payment.hasPaidApplication.invalidate();
      break;

    case "hasUploadedDocuments":
      console.log("got hasUploadedDocuments", parsed);
      if (
        "policyID" in parsed.properties &&
        typeof parsed.properties.policyID === "string"
      ) {
        void utils.form.statuses.invalidate({
          policyID: parsed.properties.policyID,
        });
        void utils.form.hasUploaded.invalidate({
          policyID: parsed.properties.policyID,
        });

        toast(
          <>
            <p className="text-sm text-dark-teal-5 dark:text-light-teal-3">
              Your documents have been uploaded successfully.
            </p>
          </>,
        );
      }
      break;

    case "verified-mitek-user":
      console.log("got verified-mitek-user", parsed);
      void utils.mitek.pending.invalidate();
      void utils.form.statuses.invalidate();
      break;

    case "checkout.session.async_payment_succeeded":
    case "checkout.session.completed": {
      const sessionID = parsed.properties.sessionID as string;
      console.log("got checkout.session.async_payment_succeeded", parsed);
      void utils.payment.status.invalidate({ sessionID });
      break;
    }

    default:
      console.log("got unknown topic", topic, parsed);
  }
}
