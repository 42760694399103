export const siteConfig = {
  name: "Trident application",
  url: "https://application.anchorwatch.com",
  description: "",
  links: {
    twitter: "https://twitter.com/anchorwatch",
  },
};

export type SiteConfig = typeof siteConfig;
