import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@trident/ui/tooltip";
import { Icons } from "./icons";
import { MainNav } from "./main-nav";
import { SigninSignout } from "./signin-signout";
import { useAuth } from "~/providers/auth2";

export function SiteHeader() {
  const { session } = useAuth();

  return (
    <header className="w-full bg-grayscale-2">
      <div className="flex h-[80px] items-center px-6">
        <MainNav />
        <div className="hidden flex-1 items-center justify-end space-x-6 md:flex">
          {session ? (
            <>
              <Icons.house />
              <AgentInfo />
              <SigninSignout />
            </>
          ) : (
            <>
              <AgentInfo />
            </>
          )}
        </div>
      </div>
    </header>
  );
}

function AgentInfo() {
  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <Tooltip delayDuration={200}>
          <TooltipTrigger>
            <Icons.question_bubble className="cursor-pointer" />
          </TooltipTrigger>
          <TooltipContent
            align="end"
            className="max-w-[320px] rounded border-[1px] border-solid border-dark-teal-5 bg-white p-[10px] text-sm text-dark-teal-5"
          >
            Email us:
            <strong> agent@anchorwatch.com</strong>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
